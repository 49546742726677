.header{
    justify-content: space-between;
    align-items: center;
    padding: 60px 0 40px;
}

.header-logo{
    height: 78px;
    cursor: pointer;
}

.header-nav-item{
    font-size: 18px;
    font-weight: 600;
    margin: 0 20px 0 36px;
}

.download-btn{
    color: var(--ui-blue);
    cursor: pointer;
    padding-block: 20px;
    /* background-color: aliceblue; */
}

.terms-of-services{
    padding-block: 20px;
    /* padding-top: 20px; */
    align-items: center;
    color: var(--ui-lightGreen);
    /* background-color: red; */
}


.download-btn-wrapper{
    /* background-color: red; */
    padding-block: 20px;
    margin-left: 20px;
}

/* Mobile Nav-Bar */
.mobile-menu-wrapper{
    background: var(--ui-dark);
    position: relative;
}

.mobile-menu{
    position: fixed;
    background-color: var(--bg-black);
    display: flex;
    flex-direction: column;
    transform: translateX(100%);
    height: 100vh;
    width: 100vw;
    text-align: left;
    padding: 32px;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out 0s;
    z-index: 1;
}

.mobile-menu-button-wrapper{
    z-index: 2;
}

.overlay{
    transform: translateX(0);
}

.mobile-navbar{
    margin-top: 95px;
    /* overflow-y: scroll; */
    width: 90%;
}

.mobile-nav-item{
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 15px;
    font-style: 15px;
    font-weight: 600;
    background-color: var(--bg-light-gray);
}

.mobile-nav-item a{
    color: var(--white);
}

.mobile-terms{
    background-color: var(--ui-blue);
    color: var(--bg-black);
}

.mobile-download{
    background-color: var(--bg-red);
}

@media all and (max-width: 1279px){
    .header-logo{
        height: 53px;
    }

    .header-nav-item{
        margin: 0 16px 0 28px;
    }
}
@media all and (max-width: 767px){
    .header{
        padding-bottom: 50px;
    }

    .header-logo{
        height: 72px;
    }
}

@media all and (max-width: 640px) {
    .mobile-download{
        margin-right: 10px;
    }

    .mobile-terms{
        margin-right: 10px;
    }
}
