.button-wrapper{
    cursor: pointer;
    /* background-image: linear-gradient(to right, #86bcf3, var(--ui-blue), var(--ui-blue)); */
    background-color: var(--ui-blue);
    color: var(--white);
    font-size: 24px;
    font-weight: 700;
    padding: 20px 65px;
    border-radius: 40px;
    width: max-content;
}

.button-wrapper:hover{
    background-color: #0c5dae;
    transition: .3s all ease-in-out;
}

@media all and (max-width: 767px){
    .button-wrapper{
        font-size: 18px;
        padding: 16px 24px;
    }
}
