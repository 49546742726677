.hero-section-wrapper{
    position: relative;
    background: var(--ui-dark);
}

.hero-section{
    min-height: 100vh;
    text-align: center;
}

.full-hero-section-wrapper{
    /* border: 2px solid black; */
    position: relative;
    bottom: 80px;
}

.hero-heading{
    font-size: 95px;
    font-weight: 700;
}

.hero-subheading{
    font-size: 26px;
    font-weight: 700;
    margin: 35px 58px;
    line-height: 38px;
}

.hero-button{
    background-color: var(--bg-red);
    outline: 2px solid red;
}

@media all and (max-width: 1279px) {
    .hero-heading{
        font-size: 66px;
        line-height: 92px;
    }

    .hero-subheading{
        font-size: 21px;
    }

    .full-hero-section-wrapper{
        bottom: 0;
    }
}

@media all and (max-width: 767px) {
    .hero-section{
        align-items: flex-start;
        text-align: left;
        min-height: 50vh;

    }

    .hero-heading{
        font-size: 45px;
        line-height: 47.5px;
        width: 270px;
        margin-top: 60px;
    }

    .hero-subheading{
        font-size: 16px;
        line-height: 22px;
        margin: 35px 0px;
    }

    .full-hero-section-wrapper{
        bottom: 0;
    }
    
    .hero-button{
        position: relative;
        top: 10px;
    }

}