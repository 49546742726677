/* & Common Classes */
html{
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.max-width {
  max-width: 70%;
  margin: auto;
}

.width{
  width: 22.3vw;
}

.flex {
  display: flex;
}

.absolute-center {
  justify-content: center;
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.flex-row{
  flex-direction: row;
}

a{
  text-decoration: none;
}

/* ^ Photo Section */

.photo-section{
  height: 100%;
  min-height: 100vh;
  min-width: 100vw;
  background-size: cover;
}

.photo-section-child{
  padding: 240px 0 180px;
}

.photo-section-heading{
  font-size: 100px;
  line-height: 96px;
  font-weight: 700;
  max-width: 700px;
}

.photo-section-subheading{
  font-size: 32px;
  line-height: 38px;
  font-weight: 700;
  max-width: 630px;
  margin-top: 20px;
  margin-bottom: 48px;
}

.photo-section-description{
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  max-width: 630px;
  margin-top: 7px;
  margin-bottom: 40px;
}

.only-mobile{
    display: none !important;
}

@media all and (max-width: 1279px) {
  .max-width {
    max-width: 80%;
  }

  .photo-section-heading{
    max-width: 670px;
  }

  .photo-section-child{
    padding: 100px 0;
  }
}

@media all and (max-width: 767px) {
  .max-width {
    max-width: 90%;
  }
  .non-mobile {
    display: none !important;
  }

  .photo-section-heading{
    font-size: 65px;
    line-height: 75px;
  }

  .photo-section-subheading{
    font-size: 18px;
    line-height: 24px;
    margin-top: 16px;
  }

  .photo-section-description{
    /* font-size: 14px; */
    /* font-weight: 400; */
    /* line-height: 20px; */

    margin-top: 0px;
  }

  .photo-section-child{
    padding: 195px 0px 90px;
  }
}

@media all and (max-width: 767px) {
  .only-mobile {
    display: block !important;
  }
}