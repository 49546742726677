/* ^ Cred Security Section */

.cred-security{
    background-image: url('./nature-img.jpg');
    background-color: var(--bg-purple);
    background-color: #2dc653;
    background-color: #358f80;
    background-image: url('./nature-texture-img-3.png');
}

@media all and (max-width: 1279px){
    .cred-security{
        background-position: center bottom;
    }
}

@media all and (max-width: 767px){
    .cred-security{
        background-image: url('nature-texture-img-3.png');
        min-height: 1150px;

        background-position: right center;
    }
}