.window-peek{
    background: url('./Finances\ UI.png');   
    min-height: 700px;
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (max-width: 767px) {
    .window-peek{
        min-height: 500px;
    }
}