/* & Mobile Scroll */
.mobile-scroll{
    position: relative;
    padding-top: 70px;
    padding-bottom: 70px;
    background-color: var(--bg-black);
}

.full-section-wrapper-mobile-scroll{
    background-color: var(--bg-black);
}

.scroll-full-screen-wrapper{
    width: 50%;
}

.scroll-full-screen{
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.mobile-mockup-wrapper{
    width: 50%;
}

.mobile-mockup{
    position: sticky;
    top: 30px;
    transform: translateX(30%);
    width: 345px;
    height: 656px;
    border-radius: 46px;
    padding: 16px 14px;
    max-height: 100vh;
    box-shadow: var(--mobile-box-shadow);
}

.mobile-mockup-screen{
    background-color: var(--bg-black);
    overflow: hidden;
    height: 100%;
    border-radius: inherit;
    margin-bottom: 200px;
}

.mobile-screen-img{
    height: 100%;
    object-fit: contain;
    background-position: center center;
}

.screen-heading{
    font-size: 105px;
    font-weight: 700;
    line-height: 105px;
}

.screen-text{
    opacity: 0;
    padding-bottom: 80px;
}

.screen-description{
    font-size: 20px;
    font-weight: 600;
    margin-top: 52px;
}

.text-visible{
    opacity: 1;
    transition: .75s;
}

@media all and (max-width: 1279px) {
    .mobile-scroll{
        padding-bottom: 30px;
    }

    .mobile-mockup{
        width: 252px;
        height: 440px;
        transform: translateX(10%);
    }

    .screen-heading{
        font-size: 70px;
        line-height: 70px;
    }

    .description{
        font-size: 18px;
        margin-top: 44px;
    }
}

@media all and (max-width: 797px) {
    .screen-heading{
        font-size: 50px;
        line-height: 50px;
    }

    .description{
        font-size: 18px;
        margin-top: 44px;
    }

    .scroll-full-screen-wrapper{
        width: 100%;
    }
}


@media all and (max-width: 640px) {
    .mobile-mockup-wrapper{
        /* outline: 2px solid lime; */
        position: relative;
        right: 20px;
        top: 4px;
    }
}