.contact-form-wrapper {
  margin-top: 40px;
  /* background: url('./image-2\ \(1\).png'); */
}

.container {
  padding: 25px 40px 10px 40px;
}

.container:focus {
  outline: none;
}
.container .text {
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  background: #fff;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.container form {
  padding: 30px 0 0 0;
}
.container form .form-row {
  display: flex;
  margin: 32px 0;
}
form .form-row .input-data {
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}
form .form-row .textarea {
  height: 70px;
}
.input-data input,
.textarea textarea {
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 17px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.12);
  background-color: var(--ui-dark);
  color: #fff;
}
.input-data input:focus ~ label,
.textarea textarea:focus ~ label,
.input-data input:valid ~ label,
.textarea textarea:valid ~ label {
  transform: translateY(-20px);
  font-size: 14px;
  color: #3498db;
  border: none;
  outline: none;
}
.textarea textarea {
  resize: none;
  padding-top: 20px;
}
.input-data label {
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
  color: #fff;
}
.textarea label {
  width: 100%;
  bottom: 40px;
  /* background: #fff; */
}
.input-data .underline {
  background-color: #fff;
  position: absolute;
  height: 2px;
  width: 100%;
}

.feedback-textarea{
  padding-bottom: 16px;
  padding-top: 20px;
}

.underline-textarea{
  bottom: -26px;
  
}

.input-data .underline:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before {
  transform: scale(1);
}
.submit-btn .input-data {
  overflow: hidden;
  height: 45px !important;
  width: 25% !important;
}
.submit-btn .input-data .inner {
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: #1e90ff;
  transition: all 0.4s;
}
.submit-btn .input-data:hover .inner {
  left: 0;
}
.submit-btn .input-data input {
  background: none;
  border: none;
  /* color: #fff; */
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  position: relative;
  
  z-index: 2;
}
.contact-btn {
  padding: 10px;
  position: relative;
  left: 20px;
  top: 30px;
  /* outline: 2px solid lime; */
}

.contact-btn {
  cursor: pointer;
  /* background-image: linear-gradient(to right, #86bcf3, var(--ui-blue), var(--ui-blue)); */
  color: var(--white);
  font-size: 18px;
  font-weight: 700;
  padding: 10px 30px;
  border-radius: 4px;
  border: none;
  width: max-content;
  background-color: var(--bg-red);
  font-family: 'Lato', sans-serif;
}

.text-area-main{
  /* outline: 2px solid lime; */
  /* margin-top: 20px; */
  padding-top: 15px;
}

.contact-btn:hover {
  background-color: rgb(210, 41, 67);
}

@media all and (max-width: 767px) {
  .contact-btn {
    font-size: 18px;
    padding: 16px 24px;
  }
}

@media (max-width: 700px) {
  .container .text {
    font-size: 30px;
  }
  .container form {
    padding: 10px 0 0 0;
  }
  .container form .form-row {
    display: block;
  }
  form .form-row .input-data {
    margin: 35px 0 !important;
  }

  .contact-btn{
    padding: 10px 20px;
    left: 0;
    font-size: 16px;
  }
}
