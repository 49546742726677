/* ^ Feel Special Section */

.cred-experience{
    background-color: var(--bg-blue);
    background-image: url('./image-1.png');
    background-position: center;
}

@media all and (max-width: 1279px){
    .cred-experience{
        background-position: center bottom;
    }
}

@media all and (max-width: 767px){
    .cred-experience{
        background-image: url('./image-1.png');
        min-height: 1150px;
        background-position: center center;
    }

    .cred-expierience-description{
        margin-top: 75px;
    }

}