:root{
    --bg-black: #0f0f0f;
    --white: #f8f8f8;
    --bg-gray: #272727;
    --link-blue: rgb(126, 126, 236);
    --mobile-bg: rgb(15, 15, 15);
    --bg-light-gray: rgba(39, 43, 45, 0.43);
    --bg-red: rgb(238, 47, 76);
    --bg-blue: rgb(26, 97, 233);
    --bg-purple: rgb(90, 30, 203);
    --bg-green: rgb(2, 119, 87);
    --mobile-bg: rgb(33, 36, 38);
    --button-red: #ef233c;
    --white-scroll: hsl(192, 24%, 96%);
    --main-scroll: hsl(200, 15%, 43%);
    --main-scroll-hover: hsl(199, 17%, 54%);

    --mobile-box-shadow: rgb(176 176 178 / 16%) 0px -5px 6px 0px inset ,
        rgb(0 0 0 / 24%) 4px 6px 11px 1px inset;

    --ui-blue: #1E90FF;
    --ui-dark: #1d364c;
    --ui-dark: linear-gradient(to top right, #1D364C 15%, #03DAC5 100%);;
    --ui-dark: #101922;
    --ui-lightGreen: #03DAC5;

}

