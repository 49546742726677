.cred-story{
    /* border: 2px solid red; */
    height: 10vw;
}

.cred-story-heading-wrapper{
    /* border: 2px solid red; */
    width: 100vw;
    justify-content: space-around;
}

.cred-story-heading{
    /* border: 2px solid red; */
    font-weight: bold;
}

.cred-terms a{
    /* border: 2px solid red; */
    justify-content: flex-end;
    color: #fff;
    font-weight: bold;
}

.developer-string{
    color: var(--ui-blue);
    font-size: px;
}

.terms-Of-Services{
    color: var(--ui-lightGreen);
    font-weight: bold;
    cursor: pointer;
}

@media all and (max-width: 797px){
    .cred-story{
        height: 20vh;
    }

    .cred-story-heading-wrapper{
        /* border: 2px solid red; */
        justify-content: space-between;
        flex-direction: column;
        gap: 24px;
        text-align: center;
    }
    
    
    .cred-story-heading{
        font-weight: bold;
        font-size: 18px;
    }

    .cred-terms {
        /* border: 2px solid red; */
        text-align: center;
        color: #fff;
        font-weight: bold;
        justify-content: center;
    }
}